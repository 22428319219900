@font-face {
    font-family: "flaticon";
    src: url("../fonts/flaticon.eot");
    src: url("../fonts/flaticond41d.eot?#iefix") format("embedded-opentype"),
        url("../fonts/flaticon.woff2") format("woff2"),
        url("../fonts/flaticon.woff") format("woff"),
        url("../fonts/flaticon.ttf") format("truetype"),
        url("../img//favicon.svg") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-right-arrow:before {
    content: "\f101";
}

.flaticon-down-arrow:before {
    content: "\f102";
}

.flaticon-right-arrow-1:before {
    content: "\f103";
}

.flaticon-left:before {
    content: "\f104";
}

.flaticon-next:before {
    content: "\f105";
}

.flaticon-mouse-cursor:before {
    content: "\f106";
}

.flaticon-arrow:before {
    content: "\f107";
}

.flaticon-sync:before {
    content: "\f108";
}

.flaticon-puzzle-piece:before {
    content: "\f109";
}

.flaticon-profit:before {
    content: "\f10a";
}

.flaticon-dashboard:before {
    content: "\f10b";
}

.flaticon-development:before {
    content: "\f10c";
}

.flaticon-budget:before {
    content: "\f10d";
}

.flaticon-mission:before {
    content: "\f10e";
}

.flaticon-briefcase:before {
    content: "\f10f";
}

.flaticon-challenges:before {
    content: "\f110";
}

.flaticon-report:before {
    content: "\f111";
}

.flaticon-investment:before {
    content: "\f112";
}

.flaticon-taxes:before {
    content: "\f113";
}

.flaticon-briefcase-1:before {
    content: "\f114";
}

.flaticon-design:before {
    content: "\f115";
}

.flaticon-money:before {
    content: "\f116";
}

.flaticon-rocket:before {
    content: "\f117";
}

.flaticon-piggy-bank:before {
    content: "\f118";
}

.flaticon-save-money:before {
    content: "\f119";
}

.flaticon-business-presentation:before {
    content: "\f11a";
}

.flaticon-data-management:before {
    content: "\f11b";
}

.flaticon-folder:before {
    content: "\f11c";
}

.flaticon-handshake:before {
    content: "\f11d";
}

.flaticon-report-1:before {
    content: "\f11e";
}

.flaticon-calculator:before {
    content: "\f11f";
}

.flaticon-settings:before {
    content: "\f120";
}

.flaticon-layers:before {
    content: "\f121";
}

.flaticon-round-table:before {
    content: "\f122";
}

.flaticon-magnifying-glass:before {
    content: "\f123";
}

.flaticon-search:before {
    content: "\f124";
}

.flaticon-user:before {
    content: "\f125";
}

.flaticon-user-1:before {
    content: "\f126";
}

.flaticon-padlock:before {
    content: "\f127";
}

.flaticon-padlock-1:before {
    content: "\f128";
}

.flaticon-time:before {
    content: "\f129";
}

.flaticon-clock:before {
    content: "\f12a";
}

.flaticon-mail:before {
    content: "\f12b";
}

.flaticon-open-email:before {
    content: "\f12c";
}

.flaticon-pin:before {
    content: "\f12d";
}

.flaticon-location:before {
    content: "\f12e";
}

.flaticon-telephone:before {
    content: "\f12f";
}

.flaticon-phone-call:before {
    content: "\f130";
}

.flaticon-support:before {
    content: "\f131";
}

.flaticon-shopping-cart:before {
    content: "\f132";
}

.flaticon-shopping-cart-1:before {
    content: "\f133";
}

.flaticon-heart:before {
    content: "\f134";
}

.flaticon-heart-1:before {
    content: "\f135";
}

.flaticon-code:before {
    content: "\f136";
}

.flaticon-folder-1:before {
    content: "\f137";
}

.flaticon-curve:before {
    content: "\f138";
}

.flaticon-inspiration:before {
    content: "\f139";
}

.flaticon-left-chevron:before {
    content: "\f13a";
}

.flaticon-trophy:before {
    content: "\f13b";
}

.flaticon-winner:before {
    content: "\f13c";
}

.flaticon-rating:before {
    content: "\f13d";
}

.flaticon-life-insurance:before {
    content: "\f13e";
}

.flaticon-car-insurance:before {
    content: "\f13f";
}

.flaticon-protection:before {
    content: "\f140";
}

.flaticon-travel-insurance:before {
    content: "\f141";
}

.flaticon-protection-1:before {
    content: "\f142";
}

.flaticon-conflagration:before {
    content: "\f143";
}

.flaticon-property-insurance:before {
    content: "\f144";
}

.flaticon-family:before {
    content: "\f145";
}

.flaticon-healthcare:before {
    content: "\f146";
}

.flaticon-house:before {
    content: "\f147";
}

.flaticon-ship:before {
    content: "\f148";
}

.flaticon-family-insurance:before {
    content: "\f149";
}

.flaticon-umbrella:before {
    content: "\f14a";
}

.flaticon-megaphone:before {
    content: "\f14b";
}

.flaticon-bubble-chat:before {
    content: "\f14c";
}

.flaticon-speech-bubble:before {
    content: "\f14d";
}