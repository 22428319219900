.main_background {
    background-image: url(./assets/img/banner/h2_banner_bg.jpg);
}

.testimonial_container {
    background-image: url(./assets/img/bg/h2_testimonial_bg.jpg);

}

.blog_conatiner {
    background-image: url(./assets/img/bg/h2_blog_bg.jpg);
}

.inner_cta {
    background-image: url(./assets/img/bg/cta_bg.jpg);
}

.feature_bg_container {
    background-image: url(./assets/img/bg/features_bg.jpg);
}

.contact_bg {
    background-image: url(./assets/img/images/h6_contact_img.jpg);
}

.multi-feature-bg {
    background-image: url(./assets/img/bg/features_bg.jpg);
}

.split-line {
    display: block;
    text-align: start;
    position: relative;
}

.about-content-three .tg-heading-subheading .tg-element-title .split-line>div {
    position: relative;
    display: inline-block;
    opacity: 1;
    transform: translate(0px, 0px);
}

.about-content-three .tg-heading-subheading .tg-element-title .split-line>div>div {
    position: relative;
    display: inline-block;
    opacity: 1;
    transform: translate(0px, 0px);
}

.about-content-three .tg-heading-subheading .tg-element-title .split-line>div>div>div {
    position: relative;
    display: inline-block;
    opacity: 1;
    transform: translate(0px, 0px);
}

.about-content-three .tg-heading-subheading .tg-element-title .split-line>div>div>div>div {
    position: relative;
    display: inline-block;
    opacity: 1;
    transform: translate(0px, 0px);
}

.inline-block {
    position: relative;
    display: inline-block;
    opacity: 1;
    transform: translate(0px, 0px);
}

.price-text {
    text-align: center;



}



/* ////****************************************demo button style****************************************8 */

.button-container1 {
    position: fixed;
    bottom: 70px;
    right: -10px;
    z-index: 9999;
}

.demo-button {
    background-color: var(--tg-primary-color);
    color: var(--dark-blue);
    max-height: 50px;
    max-width: 150px;
    border: none;
}

.demo-button:hover {
    background-color: var(--tg-primary-color);
    color: var(--dark-blue);
    max-height: 50px;
    max-width: 150px;
    border: none;
}

.corner-button {
    border-radius: 100px 0 0 100px;
}

.arrow-button {
    animation: moveLeftRight 2s infinite alternate;
}

@keyframes moveLeftRight {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-10px);
    }

    100% {
        transform: translateX(0);
    }
}


/* /***********************pricing button style***************** */
.pricing-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

}

.pricing-tabs .btn {
    padding: 20px;
    margin: 0 10px;
    border: none;
    background-color: #f0f0f0;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.pricing-tabs .btn:hover {
    background-color: #ddd;
}

.pricing-tabs .btn.active {
    background-color: var(--tg-blue);
    color: var(--tg-secondary-color);
}

.fe-bg {
    background-color: #EEF1F5;

}

/* /**********************************sidebar****************************************/
/* Sidebar container */
.sidebar {
    position: fixed;
    top: 0;
    left: -250px;

    width: 250px;
    height: 100%;
    background-color: var(--tg-secondary-color);
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transition: left 0.3s ease;
    z-index: 9999;
}

.sidebar.open {
    left: 0;
}

/* Top row containing the logo and close button */
.sidebar .top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.sidebar .nav-logo img {
    height: 60px;
    width: 140px;
}

.sidebar .close-btn {
    cursor: pointer;
    color: #fff;
}

.sidebar .menu-box {
    padding: 20px;
}

.menu-nav {
    padding: 0;
    margin: 0;
}


.menu-nav .navigation {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-nav .navigation li {
    position: relative;
    margin-bottom: 10px;
}

.menu-nav .navigation li ul {
    /* max-height: 0;
    overflow: hidden; */
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    opacity: 0;
    background-color: var(--tg-secondary-color);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 999;
    padding-left: 0;
    margin: 0;
}


@media (max-width: 1200px) {
    .menu-nav .navigation .sub-menu {
        max-height: 0;
        overflow: hidden !important;
    }
}

.menu-nav .navigation li.expanded>ul {
    max-height: 200px;
    opacity: 1;
    transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
}

.menu-nav .navigation li ul li {
    white-space: nowrap;
}

.menu-nav .navigation li ul li a {
    display: block;
    padding: 10px;
    color: #fff;
    text-decoration: none;
}


.menu-nav .navigation li a:hover,
.menu-nav .navigation li ul li a:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Icon styles */
.menu-nav .navigation li a i {
    margin-left: 10px;
    transition: transform 0.3s ease;
}

.menu-nav .navigation li.expanded>a i {
    transform: rotate(180deg);
}

body::-webkit-scrollbar {
    display: none;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    color: #fff;
}

.accordion-buttons {
    /* background-color: var(--tg-primary-color); */
    background-color: var(--tg-secondary-color);
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    border-radius: 5px;
}

.accordion-wrap-two .accordion-body {
    background-color: #b3b32d;
}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 0px;
}

.accordion-wrap-two .accordion-body p {
    color: var(--tg-secondary-color);

}


/* //become a patner  */

.vertical-line {
    width: 1px;
    background-color: #000;
    /* Adjust the color as needed */
    height: 100%;
    margin: 0 auto;
}

.img-div {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.img-div img {
    width: 200px;
    height: 200px;
}

.text-div {
    flex-direction: column;
}

.become-text {
    color: var(--tg-primary-color);
    display: block;
    margin-top: 40px;
}

.become-number {
    color: #fff;
}

.contact-text {
    color: var(--tg-primary-color);
    font-size: 20px;
}

.contact-break {
    color: #fff;
    font-size: 25px;
    padding-top: 20px;
}

/* //scroll style */
::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/* /blog details / */
.blog-details-header {
    display: flex;
    gap: 20px;
}

.blog-header-content h4 {
    margin-bottom: 20px;

}

.content-container {
    display: flex;
    gap: 10px;
}

.blog-content-span {
    display: flex;

    gap: 20px;
}

.blog-content-span span {
    display: block;
    color: #fff;
    font-size: 13px;
}

.faq-bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.faq-content {
    background-color: var(--tg-secondary-color);
    margin-bottom: 10px;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
}

.faq-content h5 {
    color: var(--tg-primary-color);
    font-size: 18px;
}

.faq-bg p {
    color: #fff;
    font-size: 13px;
}

.faq-content p {
    width: 100% !important;
}

.cta-info-wrap img {
    height: 80px;
    width: 130px;
}