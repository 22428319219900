
.store-modal-heading {
  color: #172b4d;
}
.modal-store-items-container {
  display: flex;
  /* // justify-content: space-between; */
  flex-wrap: wrap;
}
.modal-store-item-name {
  color: brown;
  font-size: 18px;
}
.modal-store-item-description {
  width: 70%;
}
.highligh-modal-store-item {
  background-color: lightblue;
}
.modal-store-item-container {
  padding: 8px;
  margin: 10px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0px 0px 2px 0px grey;
  border-radius: 15px;
  transition: background-color 0.5s ease;
}
.modal-store-item-container:hover {
  box-shadow: 0px 0px 8px 0px grey;
  background-color: #172b4d;
  .modal-store-item-description {
      color: white;
  }
  .modal-store-item-name {
      color: yellow;
  }
  .quote-text {
    color: white;
  }
}
.modal-store-item-image {
  width: 150px;
  height: 150px;
}
.modal-go-to-store-button {
  position: absolute !important;
  right: 20px;
  bottom: 20px;
}
.quote-text {
  color: blue;
  align-self: flex-end;
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
}
.ant-modal-confirm .ant-modal-confirm-body {
  text-align: center;
}