.ant-modal.ant-modal-close-x {
    color: var(--white-font);
    font-size: 20px;
    color: var(--tg-secondary-color);

}

.ant-modal .ant-modal-header {
    color: rgba(0, 0, 0, 0.88);
    background-color: var(--tg-primary-color);
    border-radius: 8px 8px 0 0;
    margin-bottom: 8px;
    padding: 20px;
}


.ant-modal .ant-modal-body {
    padding: 15px 20px;
    margin: 0px 0 0 0;
}

.ant-modal .ant-modal-content {
    padding: 0 0;
}


.ant-select-selector {
    height: 45px;
    padding: 20px 11px !important;
    width: 100%;
    background: #EEF1F5 !important;
}


.ant-select-selector:hover {
    background: #EEF1F5;
    border-radius: 5px;
    height: 42px;
    border-radius: 10px;
    border: 1px solid var(--tg-primary-color);
}

.ant-select-selector:focus {
    border: 1px solid var(--tg-primary-color);

}

.ant-select-selector:active {
    border: 1px solid var(--tg-primary-color);

}

.ant-picker-outlined {
    background: #EEF1F5 !important;

    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
}

.ant-input {
    background: #EEF1F5;
    border-radius: 5px;
    height: 42px;
    border-radius: 10px;
}

.ant-input:hover {
    background: #EEF1F5;
    border-radius: 5px;
    height: 42px;
    border-radius: 10px;
    border: 1px solid var(--tg-primary-color);
}

.ant-input:focus {
    border: 1px solid var(--tg-primary-color);

}

.ant-input:active {
    border: 1px solid var(--tg-primary-color);

}


.submit-button {
    background-color: var(--tg-secondary-color);
    color: #fff;
    width: 120px;
    height: 40px;
}

.submit-button:hover, .cancel-button:focus {
    background-color: var(--tg-secondary-color) !important;
    color: #fff !important;
}

.cancel-button {
    background-color: rgb(206, 0, 0);
    color: #fff;
    width: 120px;
    height: 40px;
}

.cancel-button:hover, .cancel-button:focus {
    background-color: rgb(206, 0, 0) !important;
    color: #fff !important;
}

.addstudent-popup {


    .ant-form-item {
        width: auto !important;
    }

    .ant-picker {
        background-color: var(--white-font);
        width: 100% !important;
        height: 42px;
        border-radius: 5px;
    }

    .ant-modal-body {
        padding: 10px;
        margin: 0px 0 0 0;
    }

    .ant-form-item {
        margin-bottom: 10px;
    }

    .ant-select {
        margin: 0;
    }

    .ant-input-affix-wrapper {
        padding: 0;
        border: none;
    }

    #inputs {
        background-color: #fff;
        height: 40px;
        padding: 8px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        font-size: 13px;
    }


    .ant-picker-input {
        background-color: var(--white);
        border-radius: 5px;
        text-align: end;
        height: 40px;
        padding: 8px;
        border-radius: 5px;
    }

    .ant-select-single .ant-select-selector {
        background-color: #fff;
        height: 40px;
        width: 100%;
        border-radius: 5px;
    }

    .ant-select-single .ant-select-selector:hover {
        border: 1px solid var(--grey-bg);
        outline: none;
    }

    .antd-row .ant-form-item-row {
        background-color: var(--grey-bg);
    }

    .ant-input {
        background-color: var(--white-font);
        border-radius: 5px;
        height: 42px;
    }
}